/**
 * =====================================================================
 * VIDEO MODAL FUNCTIONALITY
 * =====================================================================
 */
export default () => {
  const modal = document.querySelector('.modal')
  if (modal) {
    const CLOSE_MODAL = 'close'
    const OPEN_MODAL = 'open'

    const modalContainer = modal.querySelector('.modal-container')
    const closeModal = modal.querySelector('.close-modal')

    // Crown video modal
    const videoContainer = document.querySelector('.video-item')
    let videoType = null
    if (videoContainer)
      videoType = videoContainer.getAttribute('data-video-type')

    if (videoType === 'vimeo') {
      const normalContainer = document.querySelector('.normal-container')
      if (normalContainer) normalContainer.remove()
    } else if (videoType != null) {
      const vimeoContainer = document.querySelector('.vimeo-container')
      if (vimeoContainer) vimeoContainer.remove()
    }

    // Nightguard video modal
    let videoInModal = modal.querySelector('video')
    if (!videoInModal) videoInModal = modal.querySelector('.vimeo-video')
    const playBtns = document.querySelectorAll('.video-play')

    // SRU instructors
    const articles = document.querySelectorAll('#instructors article a')

    // Available courses
    const partnerCourses = document.querySelectorAll(
      '#partnerCourses .btn-flat'
    )

    console.log('MODAL TEST')

    // Add listeners to get triggered when modal is opened
    const addOpenListeners = elements => {
      elements.forEach(openEl =>
        openEl.addEventListener('click', e => {
          console.log('MODAL TEST CLICK')
          e.preventDefault()
          e.stopPropagation()
          toggleModal(OPEN_MODAL, openEl)
        })
      )
    }

    const stopPropagationInside = el =>
      el.addEventListener('click', e => e.stopPropagation())

    if (playBtns.length) {
      addOpenListeners(playBtns)
      stopPropagationInside(videoInModal)
    }

    if (articles.length) {
      addOpenListeners(articles)
      stopPropagationInside(modalContainer)
    }

    if (partnerCourses.length) {
      addOpenListeners(partnerCourses)
      stopPropagationInside(modalContainer)
    }

    if (closeModal) {
      closeModal.addEventListener('click', () => toggleModal(CLOSE_MODAL))
    }

    modal.addEventListener('click', () => toggleModal(CLOSE_MODAL))

    const { body, documentElement } = document
    let { scrollTop } = document.documentElement
    const toggleModal = (toggle, openEl) => {
      if (toggle == CLOSE_MODAL) {
        modal.classList.remove('show')
        body.classList.remove('no-scroll')
        body.style.removeProperty('top')
        documentElement.scrollTop = scrollTop
        if (videoInModal && videoType != 'vimeo') {
          videoInModal.pause()
          videoInModal.currentTime = 0
        }
        if (videoInModal && videoType == 'vimeo') {
          const vimeoIframe = videoInModal.querySelector('iframe')
          const temp = vimeoIframe.src
          vimeoIframe.src = ''
          vimeoIframe.src = temp
        }
      }
      if (toggle == OPEN_MODAL) {
        modal.classList.add('show')
        scrollTop = documentElement.scrollTop
        body.style.top = `-${scrollTop}px`
        body.classList.add('no-scroll')
        if (
          videoInModal &&
          videoInModal.getAttribute('src') != openEl.getAttribute('data-video')
        )
          videoExtraFunctionality(openEl)
        if (articles.length) instructorsExtraFunctionality(openEl)
        if (partnerCourses.length) partnerCoursesExtraFunctionality(openEl)
      }
    }

    const videoExtraFunctionality = openEl => {
      if (videoType == 'vimeo') {
        videoInModal
          .querySelector('iframe')
          .setAttribute('src', openEl.getAttribute('data-video'))
        videoInModal.setAttribute('src', openEl.getAttribute('data-video'))
      } else {
        videoInModal.setAttribute('src', openEl.getAttribute('data-video'))
      }
    }

    const instructorsExtraFunctionality = openEl => {
      modal.querySelector(
        '.title'
      ).innerText = openEl.parentElement.parentElement.querySelector(
        'h3'
      ).innerText
      modal.querySelector(
        'img'
      ).src = openEl.parentElement.parentElement.querySelector('img').src
      modal.querySelector(
        '.subtitle'
      ).innerText = openEl.parentElement.parentElement.querySelector(
        'p'
      ).innerText
      modal.querySelector('.description').innerText =
        openEl.parentElement.parentElement.dataset.bio
    }

    const partnerCoursesExtraFunctionality = openEl => {
      modal.querySelector(
        '.title'
      ).innerText = openEl.parentElement.parentElement.querySelector(
        'h3'
      ).innerText
      modal.querySelector(
        'img'
      ).src = openEl.parentElement.parentElement.querySelector('img').src
      modal.querySelector(
        '.subtitle'
      ).innerText = openEl.parentElement.parentElement.querySelector(
        '.subtitle'
      ).innerText
      modal.querySelector('.description').innerHTML =
        openEl.parentElement.parentElement.dataset.bio
      modal.querySelector(
        '.modal-course-objectives'
      ).innerHTML = openEl.parentElement.parentElement.querySelector(
        '.modal-course-objectives'
      ).innerHTML
      modal.querySelector(
        '.btn span'
      ).innerText = openEl.parentElement.parentElement.querySelector(
        'a.btn span'
      ).innerText
      modal
        .querySelector('.btn')
        .setAttribute(
          'href',
          openEl.parentElement.parentElement
            .querySelector('a.btn')
            .getAttribute('href')
        )
    }
  }
}
